import ImageMapper from 'react-img-mapper';
import React from 'react';
import { useLayoutEffect } from 'react';
import { useRef } from 'react';

export function ArcadaInferior({seleccion, limpiar}) {
  const componente = useRef(null);

  const limpiarSeleccion = () => {
    componente.current.clearHighlightedArea();
  };

  useLayoutEffect(() => {
    if(limpiar)
      limpiarSeleccion();
  }, [limpiar]);

  const MapaArcadaInferior = {
    name: 'ArcadaInferior',
    areas: [
      { "id": "inferior48", "title": "48", "shape": "circle", "name": "48", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [20, 32, 14], },
      { "id": "inferior47", "title": "47", "shape": "circle", "name": "47", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [23, 59, 14], },
      { "id": "inferior46", "title": "46", "shape": "circle", "name": "46", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [28, 87, 14], },
      { "id": "inferior45", "title": "45", "shape": "circle", "name": "45", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [38, 111, 10], },
      { "id": "inferior44", "title": "44", "shape": "circle", "name": "44", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [47, 129, 10], },
      { "id": "inferior43", "title": "43", "shape": "circle", "name": "43", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [61, 144, 10], },
      { "id": "inferior42", "title": "42", "shape": "circle", "name": "42", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [76, 153, 10], },
      { "id": "inferior41", "title": "41", "shape": "circle", "name": "41", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [90, 157, 10], },
      { "id": "inferior31", "title": "31", "shape": "circle", "name": "31", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [107, 157, 10], },
      { "id": "inferior32", "title": "32", "shape": "circle", "name": "32", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [123, 153, 10], },
      { "id": "inferior33", "title": "33", "shape": "circle", "name": "33", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [137, 144, 10], },
      { "id": "inferior34", "title": "34", "shape": "circle", "name": "34", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [150, 129, 10], },
      { "id": "inferior35", "title": "35", "shape": "circle", "name": "35", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [160, 111, 10], },
      { "id": "inferior36", "title": "36", "shape": "circle", "name": "36", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [168, 87, 14], },
      { "id": "inferior37", "title": "37", "shape": "circle", "name": "37", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [176, 59, 14], },
      { "id": "inferior38", "title": "38", "shape": "circle", "name": "38", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [177, 30, 14], },
    ],
  };

  return <ImageMapper height={171} width={200} containerRef={componente} key={"ArcadaInferior"} stayHighlighted natural  src={require("assets/img/icons/common/dental_arch_lower.png").default} map={MapaArcadaInferior} onClick={(e)=> seleccion(e,"inferior")}/>
}