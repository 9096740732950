import ImageMapper from 'react-img-mapper';
import React from 'react';
import { useLayoutEffect } from 'react';
import { useRef } from 'react';

export function ArcadaSuperior({seleccion, limpiar}) {
  const componente = useRef(null);

  const limpiarSeleccion = () => {
    componente.current.clearHighlightedArea();
  };

  useLayoutEffect(() => {
    if(limpiar)
      limpiarSeleccion();
  }, [limpiar]);

  const MapaArcadaSuperior = {
    name: 'ArcadaSuperior',
    areas: [
      { "id": "superior18", "title": "18", "shape": "circle", "name": "18", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [22,140,14],},
      { "id": "superior17", "title": "17", "shape": "circle", "name": "17", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [19,114,14],},
      { "id": "superior16", "title": "16", "shape": "circle", "name": "16", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [25,89,14],},
      { "id": "superior15", "title": "15", "shape": "circle", "name": "15", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [34,67,10],},
      { "id": "superior14", "title": "14", "shape": "circle", "name": "14", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [41,49,10],},
      { "id": "superior13", "title": "13", "shape": "circle", "name": "13", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [52,32,10],},
      { "id": "superior12", "title": "12", "shape": "circle", "name": "12", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [68,20,10],},
      { "id": "superior11", "title": "11", "shape": "circle", "name": "11", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [88,14,10],},
      { "id": "superior21", "title": "21", "shape": "circle", "name": "21", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [109,14,10],},
      { "id": "superior22", "title": "22", "shape": "circle", "name": "22", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [130,20,10],},
      { "id": "superior23", "title": "23", "shape": "circle", "name": "23", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [147,31,10],},
      { "id": "superior24", "title": "24", "shape": "circle", "name": "24", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [156,49,10],},
      { "id": "superior25", "title": "25", "shape": "circle", "name": "25", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [164,66,10],},
      { "id": "superior26", "title": "26", "shape": "circle", "name": "26", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [173,90,14],},
      { "id": "superior27", "title": "27", "shape": "circle", "name": "27", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [179,115,14],},
      { "id": "superior28", "title": "28", "shape": "circle", "name": "28", "fillColor": "#eab54d4d", "strokeColor": "red", "coords": [177,140,14],},
    ],
  };
  
  return <ImageMapper height={171} width={200} containerRef={componente} key={"ArcadaSuperior"} stayHighlighted natural src={require("assets/img/icons/common/dental_arch_upper.png").default} map={MapaArcadaSuperior} onClick={(e)=> seleccion(e, "superior")}/>
}