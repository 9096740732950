import * as ControllerAgenda from 'controllers/Agenda';
import * as ControllerConsultorios from 'controllers/Consultorios';

import { Button, IconButton } from "@material-ui/core";
import React, { useEffect, useLayoutEffect } from 'react';
import { ThemeProvider, createTheme, makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Calendario from "components/Agenda/Calendario"
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import { FormularioReserva } from "components/Agenda/FormularioReserva";
import Grid from "@material-ui/core/Grid";
import Header from "components/Headers/Header.js";
import { NotificationsActiveOutlined } from '@material-ui/icons';
import Pendientes from "components/Agenda/Pendientes"
import Typography from "@material-ui/core/Typography";
import componentStyles from "assets/theme/views/admin/tables.js";
import { useAlert } from "react-alert";
import { useSelector } from 'react-redux';

const useStyles = makeStyles(componentStyles);
const theme = createTheme({});

const Agenda = () => {
  const classes = useStyles();
  const alert = useAlert();
  const [citas, setCitas] = React.useState([]);
  const [citasPendientes, setCitasPendientes] = React.useState([]);
  const [consultorios, setConsultorios] = React.useState([]);
  const [mostrar, setMostrar] = React.useState(false);
  const permisos = useSelector((state) => state.usuario && state.usuario.permisos ? state.usuario.permisos.reserva : []);
  const permisosNotificaciones = useSelector((state) => state.usuario && state.usuario.permisos ? state.usuario.permisos.notificacion : []);

  const obtenerCitas = async () => {
    const r = await ControllerAgenda.peticionGetCitas();
    if (r.resultado) {
      setCitas(r.data);
      const citasPendientes = r.data ? r.data.filter(i => i.reservaEstado && i.reservaEstado.idReservaEstado < 4) : [];
      setCitasPendientes(citasPendientes ? citasPendientes.sort((a, b) => a.fechaInicio - b.fechaInicio) : []);
    } else {
      alert.error(r.mensaje);
    }
  }

  const obtenerConsultorios = async () => {
    const r = await ControllerConsultorios.peticionGetConsultorios();
    if (r.resultado) {
      setConsultorios(r.data ? r.data.registros : []);
    } else {
      alert.error(r.mensaje);
    }
  }

  const abrirReservaNueva = () => {
    setMostrar(true);
  }

  const cerrarReservaNueva = () => {
    setMostrar(false);
  }


  useEffect(() => {
    //Pendiente de evento 'cambioAgenda' para recargar datos
    window.addEventListener('cambioAgenda', () => {
      obtenerCitas();
    })
  }, []);

  useLayoutEffect(() => {
    obtenerConsultorios();
    obtenerCitas();
  }, []);

  return (
    <>
      <Header />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-12rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container component={Box} marginTop="3rem">
          <Grid
            item
            xs={12}
            xl={8}
            component={Box}
            marginBottom="3rem!important"
            classes={{ root: classes.gridItemRoot }}
          >
            <Card
              classes={{
                root: classes.cardRoot,
              }}
            >

              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        Calendario
                      </Box>
                    </Grid>
                    <Grid item xs="auto">
                      {permisos && permisos.crear &&
                        <Button color="secondary" variant="contained" onClick={() => abrirReservaNueva()}>
                          Reservar
                        </Button>
                      }
                    </Grid>
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <CardContent>
                <ThemeProvider theme={theme}>
                  <Calendario citas={citas} consultorios={consultorios} permisos={permisos} recargar={obtenerCitas} />
                </ThemeProvider>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} xl={4}>
            <Card classes={{ root: classes.cardRoot }}>
              <CardHeader
                title={
                  <Box component="span" fontSize={"small"}>
                    Citas pendientes
                  </Box>
                }
                subheader={
                  <Grid container>
                    <Grid item xs={8} xl={8}>
                      <Box component="span">
                        Seguimiento
                      </Box>
                    </Grid>
                    {permisosNotificaciones && permisosNotificaciones.listar && <Grid item xs={4} xl={4} style={{ textAlign: "end" }}>
                      <IconButton title='Notificaciones pendientes' href='/app/notificaciones/listado' style={{ width: "3rem", height: "3rem" }} >
                        <NotificationsActiveOutlined style={{ transform: "scale(3)", opacity: "0.5", color: "red" }} />
                      </IconButton>
                    </Grid>}
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
                titleTypographyProps={{
                  component: Box,
                  variant: "h6",
                  letterSpacing: ".0625rem",
                  marginBottom: ".25rem!important",
                  classes: {
                    root: classes.textUppercase,
                  },
                }}
                subheaderTypographyProps={{
                  component: Box,
                  variant: "h2",
                  marginBottom: "0!important",
                  color: "initial",
                }}
              ></CardHeader>
              <CardContent>
                <Box position="relative" >
                  <Pendientes citas={citasPendientes} permisos={permisos} />
                </Box>
              </CardContent>
            </Card>
          </Grid>

        </Grid >
      </Container >
      {mostrar && <FormularioReserva mostrar={mostrar} cerrar={cerrarReservaNueva} />}
    </>
  );
}




export default Agenda;