import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import InputBase from "@material-ui/core/InputBase";
import NavbarDropdown from "components/Dropdowns/NavbarDropdown.js";
import SearchIcon from "@material-ui/icons/Search";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import componentStyles from "assets/theme/components/admin-navbar.js";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles(componentStyles);

export default function AdminNavbar({ brandText, buscarPaciente }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const [busquedaPaciente, setBusquedaPaciente] = React.useState({});
  const permisos = useSelector((state) => state.usuario && state.usuario.permisos ? state.usuario.permisos.paciente : []);

  const guardarDatos = e => {
    setBusquedaPaciente({ criterio: e.target.value });
  };

  
  useEffect(() => {
    const param = new URLSearchParams(search).get("busqueda") ?? "";
    if(busquedaPaciente.criterio !== param) setBusquedaPaciente({ criterio: param });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <>
      <AppBar
        position="absolute"
        color="transparent"
        elevation={0}
        classes={{ root: classes.appBarRoot }}
      >
        <Toolbar disableGutters>
          <Container
            maxWidth={false}
            component={Box}
            classes={{ root: classes.containerRoot }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              marginTop="0.5rem"
            >
              <div>
                <Typography
                  className={classes.brandTitle}
                  variant="h4"
                  component="a"
                >
                  {brandText}
                </Typography>
              </div>
              <Box display="flex" alignItems="center" width="auto">
                {permisos && permisos.buscar &&
                  <Box
                    display="flex"
                    alignItems="center"
                    width="auto"
                    marginRight="1rem"
                    classes={{
                      root: classes.searchBox,
                    }}
                  >
                    <SearchIcon className={classes.searchIcon} />
                    <InputBase
                      placeholder="Buscar paciente"
                      id='criterio'
                      name='criterio'
                      classes={{
                        input: classes.searchInput,
                      }}
                      value={busquedaPaciente?.criterio}
                      onChange={guardarDatos}
                      onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                          buscarPaciente(busquedaPaciente);
                          ev.preventDefault();
                        }
                      }}
                    />
                  </Box>}
                <NavbarDropdown dispatch={dispatch} />
              </Box>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
    </>
  );
}
