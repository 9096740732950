import * as ControllerAutenticacion from 'controllers/Autenticacion';

import React, { useEffect, useRef, useState } from "react";
import { Switch, useHistory } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import AuthFooter from "components/Footers/AuthFooter.js";
import AuthHeader from "components/Headers/AuthHeader.js";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Carga from "components/Efectos/Carga";
import Container from "@material-ui/core/Container";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { IconButton } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Lock from "@material-ui/icons/Lock";
import Person from "@material-ui/icons/Person";
import ValidacionForm from "components/Mensaje/ValidacionForm";
import { b64aBlob } from "functions/formulario";
import componentStyles from "assets/theme/views/auth/login.js";
import { evaluarForm } from "functions/formulario";
import { guardarRegistro } from "functions/browserDB";
import { loginUsuario } from "controllers/Autenticacion";
import { makeStyles } from "@material-ui/core/styles";
import { obtenerPermisos } from "functions/permisos";
import { useAlert } from "react-alert";
import { useTheme } from "@material-ui/core/styles";
import { validarStringNumerico } from "functions/formulario";
const useStyles = makeStyles(componentStyles);


export default function Login() {
  const classes = useStyles();
  const theme = useTheme();
  const botonLogin = useRef();
  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();
  const [mostrarPassword, setMostrarPassword] = useState(false);
  const [estadoBoton, setEstadoBoton] = useState(true);
  const [campoUsuario, setCampoUsuario] = useState();
  const [campoClave, setCampoClave] = useState();
  const [mensajeValidacion, setMensajeValidacion] = useState();
  const mainContent = React.useRef(null);
  const camposRequeridos = [{ id: 'cedula', nombre: 'Usuario' }, { id: 'clave', nombre: 'Contraseña' }];
  const carga = useSelector((state) => state.carga);

  useEffect(() => {
    document.body.classList.add(classes.bgDefault);
    return () => {
      document.body.classList.remove(classes.bgDefault);
    };
  });

  const visualizacionPassword = () => setMostrarPassword((mostrarPassword) => !mostrarPassword);

  const habilitacionBoton = () => {
    if (botonLogin && botonLogin.current){
      botonLogin.current.disabled = !botonLogin.current.disabled;
      setEstadoBoton(!botonLogin.current.disabled);
    }
  }

  const obtenerDatosSesion = async () => {
    const rUsuario = await ControllerAutenticacion.peticionGetSesion();
    if (!rUsuario.resultado) {
      alert.error(rUsuario.mensaje);
      return;
    }

    //Generación de permisos
    const idRolesUsuario = rUsuario.data.roles ? rUsuario.data.roles.map(r => r.idRol) : [];
    const permisos = obtenerPermisos(idRolesUsuario);

    //Guardado de sesion en Redux
    dispatch({
      "type": "CARGAR_USUARIO", "usuario": {
        id: rUsuario.data.id,
        usuario: rUsuario.data.cedula,
        nombre: rUsuario.data.nombre1,
        apellido: rUsuario.data.apellido1,
        sexo: rUsuario.data.sexo,
        avatar: rUsuario.data.avatar,
        roles: rUsuario.data.roles,
        permisos: permisos
      }
    });

    //Carga de Avatar
    const archivo = rUsuario.data && rUsuario.data.avatar ? rUsuario.data.avatar : "";
    const blob = b64aBlob(archivo);
    guardarRegistro('avatar', blob).then(() =>
      //Trigger evento de aviso a NavBar para que carge avatar de localforage
      dispatchEvent(new Event("avatarCargado"))
    );
  }

  const enviarForm = async e => {
    habilitacionBoton();
    e.preventDefault();
    dispatch({ "type": "AVISO_CARGANDO", "data": true });

    const credencialesIngresadas = {
      "cedula": campoUsuario,
      "clave": campoClave
    }
    if (validarStringNumerico(credencialesIngresadas.cedula)) {
      const rValidacion = evaluarForm(credencialesIngresadas, camposRequeridos);
      if (rValidacion.resultado) {
        const r = await loginUsuario(credencialesIngresadas);
        if (r.resultado) {
          history.push("/app/inicio");
          dispatch({
            "type": "LOGIN", "sesion": {
              "token": r.data.token,
              "forzarCambioDeClave": r.data.forzarCambioDeClave
            }
          });
          obtenerDatosSesion();
          dispatch({ "type": "AVISO_CARGANDO", "data": false });
          return;
        } else {
          setMensajeValidacion(r.mensaje);
        }
      } else {
        setMensajeValidacion(rValidacion.mensaje);
      }
    } else {
      setMensajeValidacion("El nombre de usuario debe ser numérico");
    }
    dispatch({ "type": "AVISO_CARGANDO", "data": false });
    habilitacionBoton();
  }

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <AuthNavbar />
        <AuthHeader />

        <Container
          component={Box}
          maxWidth="xl"
          marginTop="-8rem"
          paddingBottom="3rem"
          position="relative"
          zIndex="101"
        >
          <Box component={Grid} container justifyContent="center">
            <Switch>
              <Grid item xs={12} lg={5} md={7}>
                <Card classes={{ root: classes.cardRoot }}>
                  <CardContent classes={{ root: classes.cardContent }}>
                    <ValidacionForm mensajeValidacion={mensajeValidacion} />
                    <Box marginTop={"1rem"}>
                      <form onSubmit={enviarForm}>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"

                        >
                          <FilledInput
                            onChange={e => setCampoUsuario(e.target.value)}
                            autoComplete="off"
                            type="number"
                            placeholder="Usuario"
                            startAdornment={
                              <InputAdornment position="start">
                                <Person />
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <FilledInput
                            inputProps={{ maxLength: 100 }}
                            onChange={e => setCampoClave(e.target.value)}
                            autoComplete="off"
                            type={mostrarPassword ? "text" : "password"}
                            placeholder="Contraseña"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Visualizar contraseña"
                                  onClick={visualizacionPassword}
                                  edge="end"
                                >
                                  {mostrarPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                            startAdornment={
                              <InputAdornment position="start">
                                <Lock />
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
                          <Button ref={botonLogin} color={estadoBoton ? "primary":"secondary"} variant="contained" type="submit">
                            Ingresar
                          </Button>
                        </Box>
                      </form>
                    </Box>
                  </CardContent>
                </Card>
                <Grid container component={Box} marginTop="1rem">
                  <Grid item xs={12} component={Box} textAlign="right">
                    <a
                      href="mailto:contacto@integraldent.uy"
                      onClick={(e) => e.preventDefault()}
                      className={classes.footerLinks}
                    >
                      Recuperar contraseña
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Switch>
          </Box>
        </Container>
      </div>
      <AuthFooter />

      {/*Overlay de página cargando*/}
      <Carga mostrar={carga} />
    </>
  );
}