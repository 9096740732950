import * as ControllerAgenda from 'controllers/Agenda';

import {
  AllDayPanel,
  AppointmentTooltip,
  Appointments,
  ConfirmationDialog,
  DateNavigator,
  DayView,
  DragDropProvider,
  MonthView,
  Resources,
  Scheduler,
  TodayButton,
  Toolbar,
  ViewSwitcher,
  WeekView,
} from '@devexpress/dx-react-scheduler-material-ui';
import { CurrentTimeIndicator, EditingState, IntegratedEditing, ViewState } from '@devexpress/dx-react-scheduler';

import { CeldaDia } from "./Celda";
import { Paper } from "@material-ui/core";
import React from "react";
import { ResumenCita } from 'components/Agenda/ResumenCita';
import { ResumenCitaLayout } from "./ResumenCitaLayout";
import { agenda_traduccion } from 'traducciones/agenda_traduccion';
import { formatoFecha } from "functions/fechas";
import { withAlert } from 'react-alert'

class Calendario extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      startDayHour: 9,
      endDayHour: 19,
    };
    this.currentDateChange = (currentDate) => { this.setState({ currentDate }); };
    this.guardarCambios = this.guardarCambios.bind(this);
    this.modificarCita = this.modificarCita.bind(this);
    this.recargar = this.props.recargar.bind(this);
  }

  async modificarCita(id, reservaModificada) {
    const { alert } = this.props;
    const r = await ControllerAgenda.peticionPatchCita(id, { fechaInicio: reservaModificada.fechaInicio, fechaFin: reservaModificada.fechaFin });
    if (r.resultado) {
      this.recargar();
      alert.success(r.mensaje);
    } else {
      alert.error(r.mensaje);
    }
  }

  guardarCambios({ added, deleted, changed }) {
    const { alert } = this.props;

    this.setState(async (state) => {
      let data = this.props.citas || [];

      if (changed) {
        if (data && data.length > 0) {
          data.forEach(async appointment => {
            if (changed[appointment.idReserva]) {
              let reservaModificada = {
                ...appointment,
                fechaInicio: formatoFecha(changed[appointment.idReserva].startDate),
                fechaFin: formatoFecha(changed[appointment.idReserva].endDate),
              }             
              const r = await this.modificarCita(reservaModificada.idReserva, reservaModificada);
            }
          });
        } else {
          alert.error("No se puede realizar la modificación de fecha");
        }
      }
      return { data };
    });
  }


  render() {
    const { currentDate } = this.state;
    const resources = [
      {
        fieldName: 'idConsultorio',
        title: 'Consultorio',
        defaultValue: '',
        instances: this.props.consultorios && this.props.consultorios.length > 0 ? this.props.consultorios.map((i) => ({ text: i.nombre, id: i.idConsultorio, color:i.color })) : [],
      }];

    return (
      <Paper >
        <Scheduler
          locale={"es-419"}
          timezone='America/Montevideo'
          data={this.props.citas.map((i) => ({
            ...i,
            id: i.idReserva,
            title: i && i.paciente && i.paciente.nombre1 && i.paciente.apellido1 ? `${i.paciente.nombre1} ${i.paciente.apellido1}` : "Desconocido",
            startDate: i.fechaInicio,
            endDate: i.fechaFin,
            idConsultorio: i.consultorio.idConsultorio
          })) || []}
          height={800}
        >
          <ViewState
            currentDate={currentDate}
            onCurrentDateChange={this.currentDateChange}
          />
          <EditingState onCommitChanges={this.guardarCambios} />
          <IntegratedEditing />
          <WeekView displayName="Semana" timeTableCellComponent={CeldaDia}/>
          <MonthView displayName="Mes" />
          <DayView displayName="Día"  timeTableCellComponent={CeldaDia} />
          <Toolbar />
          <ViewSwitcher messages={agenda_traduccion['es-UY']} />
          <DateNavigator />
          <TodayButton messages={agenda_traduccion['es-UY']} />
          <AllDayPanel messages={agenda_traduccion['es-UY']} />
          <ConfirmationDialog messages={agenda_traduccion['es-UY']} />
          <Appointments />
          <DragDropProvider />
          <CurrentTimeIndicator shadePreviousCells={true} shadePreviousAppointments={true} />
          <AppointmentTooltip
            contentComponent={ResumenCita}
            layoutComponent={ResumenCitaLayout}
            showCloseButton
            showOpenButton
            showDeleteButton
          />
          <Resources data={resources} mainResourceName="idConsultorio" />
        </Scheduler>
      </Paper >
    );
  }
}

export default withAlert()(Calendario);