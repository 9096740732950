import * as ControllerPacientes from 'controllers/Pacientes';

import React, { useLayoutEffect } from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Foto from "components/Formularios/Foto";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import UserHeader from "components/Headers/UserHeader.js";
import { acortarTexto } from "functions/formulario";
import { b64aBlob } from "functions/formulario";
import { estiloPerfil } from "estilos/clases.js";
import { formatoFecha } from 'functions/fechas';
import { obtenerEtiquetaSexo } from "functions/general";
import { useAlert } from "react-alert";
import { useParams } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";

function Paciente() {
  let { id } = useParams();
  const theme = useTheme();
  const estilo = estiloPerfil();
  const alert = useAlert();
  const [paciente, setPaciente] = React.useState({});
  const [patologias, setPatologias] = React.useState([]);
  const [alergias, setAlergias] = React.useState([]);
  const [medicamentos, setMedicamentos] = React.useState([]);
  const [contactos, setContactos] = React.useState([]);
  const [avatar, setAvatar] = React.useState();

  const obtenerAvatar = async () => {
    const r = await ControllerPacientes.peticionGetPacienteAvatar(id);
    if (r.resultado) {
      if (r.data) {
        const blob = b64aBlob(r.data.base64 ? r.data.base64 : "");
        setAvatar(blob);
      }
    } else {
      alert.error(r.mensaje);
    }
  };

  const obtenerPaciente = async () => {
    if (id) {
      const r = await ControllerPacientes.peticionGetPaciente(id);
      if (r.resultado) {
        setPaciente(r.data);
        obtenerPacientePatologias();
        obtenerPacienteMedicamentos();
        obtenerPacienteAlergias();
        obtenerContactos();
      } else {
        alert.error(r.mensaje);
      }
    }
  };

  const obtenerContactos = async () => {
    if (id) {
      const r = await ControllerPacientes.peticionGetContactos(id);
      (r.resultado) ? setContactos(r.data) : alert.error(r.mensaje);
    }
  };

  const obtenerPacientePatologias = async () => {
    if (id) {
      const r = await ControllerPacientes.peticionGetPacientePatologias(id);
      if (r.resultado) {
        setPatologias(r.data);
      } else {
        alert.error(r.mensaje);
      }
    }
  };

  const obtenerPacienteMedicamentos = async () => {
    if (id) {
      const r = await ControllerPacientes.peticionGetPacienteMedicamentos(id);
      if (r.resultado) {
        setMedicamentos(r.data);
      } else {
        alert.error(r.mensaje);
      }
    }
  };

  const obtenerPacienteAlergias = async () => {
    if (id) {
      const r = await ControllerPacientes.peticionGetPacienteAlergias(id);
      if (r.resultado) {
        setAlergias(r.data);
      } else {
        alert.error(r.mensaje);
      }
    }
  };

  useLayoutEffect(() => {
    obtenerPaciente();
  }, []);

  useLayoutEffect(() => {
    if (paciente && paciente.idPaciente) {
      obtenerAvatar();
    }
  }, [paciente])

  return (
    <>
      <UserHeader />

      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: estilo.containerRoot }}
      >
        <Grid container>
          <Foto avatar={avatar} />
          <Grid
            item
            xs={12}
            xl={8}
            component={Box}
            marginBottom="3rem"
            classes={{ root: estilo.gridItemRoot + " " + estilo.order2 }}
          >
            <Card
              classes={{
                root: estilo.cardRoot + " " + estilo.cardRootSecondary,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >

                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        {'Datos de paciente'}
                      </Box>
                    </Grid>
                    <Grid item xs="auto">
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          href={`/app/pacientes/listado/`}
                        >
                          Volver
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: estilo.cardHeaderRoot }}
              ></CardHeader>
              <CardContent>
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: estilo.typographyRootH6 }}
                >
                  Datos personales
                </Box>
                <div className={estilo.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Primer Nombre</FormLabel>
                        <Typography variant="body2"> {paciente ? paciente.nombre1 : ''}</Typography>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Segundo Nombre</FormLabel>
                        <Typography variant="body2"> {paciente ? paciente.nombre2 : ''}</Typography>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Primer Apellido</FormLabel>
                        <Typography variant="body2"> {paciente ? paciente.apellido1 : ''}</Typography>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Segundo Apellido</FormLabel>
                        <Typography variant="body2"> {paciente ? paciente.apellido2 : ''}</Typography>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Tipo de documento</FormLabel>
                        <Typography variant="body2"> {paciente && paciente.tipoDocumento ? paciente.tipoDocumento.nombre : ''}</Typography>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Documento</FormLabel>
                        <Typography variant="body2"> {paciente ? paciente.documento : ''}</Typography>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Sexo</FormLabel>
                        <Typography variant="body2"> {paciente ? obtenerEtiquetaSexo(paciente.sexo) : ''}</Typography>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Nacimiento</FormLabel>
                        <Typography variant="body2"> {paciente != null ? formatoFecha(paciente.fechaNacimiento, "YYYY-MM-DD") : ''}</Typography>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </div>
                <Box
                  component={Divider}
                  marginBottom="1.5rem!important"
                  marginTop="1.5rem!important"
                />
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: estilo.typographyRootH6 }}
                >
                  Información de contacto
                </Box>
                <div className={estilo.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Teléfono</FormLabel>
                        <Typography variant="body2"> {paciente ? paciente.telefono : ''}</Typography>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Celular</FormLabel>
                        <Typography variant="body2"> {paciente ? paciente.celular : ''}</Typography>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Correo electrónico</FormLabel>
                        <Typography variant="body2"> {paciente ? paciente.mail : ''}</Typography>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Dirección</FormLabel>
                        <Typography variant="body2"> {paciente ? paciente.direccion : ''}</Typography>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Departamento</FormLabel>
                        <Typography variant="body2"> {paciente ? paciente.departamento : ''}</Typography>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>País</FormLabel>
                        <Typography variant="body2"> {paciente && paciente.pais ? paciente.pais.nombre : ''}</Typography>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </div>
                <Box
                  component={Divider}
                  marginBottom="1.5rem!important"
                  marginTop="1.5rem!important"
                />
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: estilo.typographyRootH6 }}
                >
                  Datos médicos
                </Box>
                <div className={estilo.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Mutualista</FormLabel>
                        <Typography variant="body2"> {paciente && paciente.mutualista ? paciente.mutualista.nombre : ''}</Typography>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Emergencia</FormLabel>
                        <Typography variant="body2"> {paciente && paciente.emergencia ? paciente.emergencia.nombre : ''}</Typography>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Vto Carnet de salud</FormLabel>
                        <Typography variant="body2"> {paciente != null ? formatoFecha(paciente.vencimientoCarnetSalud, "YYYY-MM-DD") : ''}</Typography>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </div>
                <Box
                  component={Divider}
                  marginBottom="1.5rem!important"
                  marginTop="1.5rem!important"
                />
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: estilo.typographyRootH6 }}
                >
                  Patologías
                </Box>
                {!patologias || patologias.length == 0 ?
                  <Typography variant="body2"> {'No hay registros'}</Typography>
                  : patologias.map(i => {
                    return (
                      <Typography variant="body2"> {`- ${i.patologia.nombre} ${i.patologia.descripcion ? ` (Descripción: ${acortarTexto(i.patologia.descripcion, 40)})` : ''}`}</Typography>
                    )
                  }
                  )}
                <Box
                  component={Divider}
                  marginBottom="1.5rem!important"
                  marginTop="1.5rem!important"
                />
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: estilo.typographyRootH6 }}
                >
                  Alergias
                </Box>
                {!alergias || alergias.length == 0 ?
                  <Typography variant="body2"> {'No hay registros'}</Typography>
                  : alergias.map(i => {
                    return (
                      <Typography variant="body2"> {`- ${i.alergia.nombre} ${i.alergia.descripcion ? ` (Descripción: ${acortarTexto(i.alergia.descripcion,120)})` : ''}`}</Typography>
                    )
                  }
                  )}
                <Box
                  component={Divider}
                  marginBottom="1.5rem!important"
                  marginTop="1.5rem!important"
                />
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: estilo.typographyRootH6 }}
                >
                  Medicamentos
                </Box>
                {!medicamentos || medicamentos.length == 0 ?
                  <Typography variant="body2"> {'No hay registros'}</Typography>
                  : medicamentos.map(i => {
                    return (
                      <Typography variant="body2"> {`- ${i.medicamento.nombre} ${i.medicamento.principioActivo ? ` (Principio activo: ${i.medicamento.principioActivo})` : ''} ${i.medicamento.laboratorio ? ` | Laboratorio: ${i.medicamento.laboratorio}` : ''}`}</Typography>
                    )
                  }
                  )}
                <Box
                  component={Divider}
                  marginBottom="1.5rem!important"
                  marginTop="1.5rem!important"
                />
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: estilo.typographyRootH6 }}
                >
                  Contactos de emergencia
                </Box>
                {!contactos || contactos.length == 0 ?
                  <Typography variant="body2"> {'No hay registros'}</Typography>
                  : contactos.map(i => {
                    return (
                      <Grid container>
                        <Grid item xs={12} lg={6}>
                          <FormGroup>
                            <FormLabel>Nombre</FormLabel>
                            <Typography variant="body2"> {i.nombre}</Typography>
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <FormGroup>
                            <FormLabel>Teléfono</FormLabel>
                            <Typography variant="body2"> {i.telefono}</Typography>
                          </FormGroup>
                        </Grid>
                      </Grid>
                    )
                  }
                  )}
              </CardContent>
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >

                    <Grid item xs="auto">
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        href="/app/pacientes/listado"
                      >
                        Volver
                      </Button>
                    </Grid>
                  </Grid>
                }
                classes={{ root: estilo.cardHeaderRoot }}
              ></CardHeader>
            </Card>
          </Grid>

        </Grid >
      </Container >
    </>
  );
}

export default Paciente;
