/* eslint-disable react-hooks/exhaustive-deps */
import * as ControllerAgenda from 'controllers/Agenda';
import * as ControllerConsultorios from 'controllers/Consultorios';
import * as ControllerDiagnosticos from 'controllers/Diagnosticos';
import * as ControllerPacientes from 'controllers/Pacientes';
import * as ControllerTratamientos from 'controllers/Tratamientos';
import * as ControllerUsuarios from 'controllers/Usuarios';

import { Dialog, DialogContent, DialogTitle, FilledInput, Paper, TextField, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { AltaTrabajoReserva } from "components/Agenda/AltaTrabajoReserva";
import { AppointmentForm } from "@devexpress/dx-react-scheduler-material-ui";
import { Autocomplete } from "@material-ui/lab";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import { ListaTrabajosReserva } from "components/Agenda/ListaTrabajosReserva";
import { ObtenerCaras } from "functions/datosSistema";
import { ObtenerPiezas } from "functions/datosSistema";
import React from "react";
import { Transicion } from "components/Efectos/Transicion";
import componentStyles from "assets/theme/views/admin/profile.js";
import { convertirTimezone } from "functions/fechas";
import { evaluarForm } from "functions/formulario";
import { formatoFecha } from "functions/fechas";
import { makeStyles } from "@material-ui/core/styles";
import { sumarMinutosFecha } from 'functions/fechas';
import { useAlert } from 'react-alert'
import { useEffect } from "react";
import { useLayoutEffect } from "react";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(componentStyles);

export const Reserva = ({ visible, cerrar, datos, id }) => {
    const classes = useStyles();
    const theme = useTheme();
    const alert = useAlert();
    const dispatch = useDispatch();
    const piezasCargadas = (useSelector((state) => state.piezas));
    const carasCargadas = (useSelector((state) => state.caras));
    const [piezas, setPiezas] = React.useState([]);
    const [caras, setCaras] = React.useState([]);
    const [reserva, setReserva] = React.useState(
        datos ?
        { fechaInicio: datos.fechaInicio, fechaFin: datos.fechaFin }
        : { fechaInicio: sumarMinutosFecha(new Date(), 30, true), fechaFin: sumarMinutosFecha(new Date(), 60, true) }
    );
    const [reservaModificada, setReservaModificada] = React.useState(
        datos ?
        { fechaInicio: datos.fechaInicio, fechaFin: datos.fechaFin }
        : { fechaInicio: sumarMinutosFecha(new Date(), 30, true), fechaFin: sumarMinutosFecha(new Date(), 60, true) }
    );
    const [pacientes, setPacientes] = React.useState([]);
    const [profesionales, setProfesionales] = React.useState([]);
    const [consultorios, setConsultorios] = React.useState([]);
    const [tratamientos, setTratamientos] = React.useState([]);
    const [diagnosticos, setDiagnosticos] = React.useState([]);
    const [cargaPaciente, setCargaPaciente] = React.useState({});
    const [cargaConsultorio, setCargaConsultorio] = React.useState({});
    const [cargaProfesional, setCargaProfesional] = React.useState({});
    const [trabajos, setTrabajos] = React.useState([]);
    const camposRequeridos = [{ id: 'fechaInicio', nombre: 'Fecha inicio' }, { id: 'fechaFin', nombre: 'Fecha fin' },
    { id: 'idPaciente', nombre: 'Paciente' }, { id: 'idMedico', nombre: 'Profesional' },
    { id: 'idConsultorio', nombre: 'Consultorio' },
    ];

    const ajusteTimeZone = (r) => {
        return {
            ...r,
            fechaInicio: formatoFecha(r.fechaInicio),
            fechaFin: formatoFecha(r.fechaFin)
        };
    }


    const evaluarFechas = (elemento) => {
        const fechaInicio = new Date(elemento.fechaInicio);
        const fechaFin = new Date(elemento.fechaFin);
        const fechaActual = new Date();
        return fechaFin > fechaInicio && fechaInicio > fechaActual;
    }

    const crearCita = async () => {
        let data = reservaModificada;
        const validarForm = evaluarForm(data, camposRequeridos);
        if (!validarForm.resultado) {
            alert.error(validarForm.mensaje);
            return;
        }
        if (!data.planificado || data.planificado.length === 0) {
            data = {...data, planificado:[]};
        }

        if (evaluarFechas(data)) {
            const r = await ControllerAgenda.peticionPostCita(ajusteTimeZone(data));
            if (r.resultado) {
                alert.success(r.mensaje);
                //Trigger evento de aviso a Calendario para recargar datos
                dispatchEvent(new Event("cambioAgenda"))
                cerrar();
            } else {
                alert.error(r.mensaje);
            }
        } else {
            alert.error("La fecha y hora de inicio debe ser menor a la final y mayor a la actual");
        }
    }


    const modificarCita = async () => {
        const validarForm = evaluarForm(reservaModificada, camposRequeridos);
        if (!validarForm.resultado) {
            alert.error(validarForm.mensaje);
            return;
        }
        const reservaFormatoAPI = {
            fechaInicio: reservaModificada.fechaInicio,
            fechaFin: reservaModificada.fechaFin,
            idMedico: reservaModificada.idMedico,
            idConsultorio: reservaModificada.idConsultorio
        }
        const r = await ControllerAgenda.peticionPatchCita(id, ajusteTimeZone(reservaFormatoAPI));
        if (r.resultado) {
            //Trigger evento de aviso a Calendario para recargar datos
            dispatchEvent(new Event("cambioAgenda"))
            alert.success(r.mensaje);
            cerrar();
        } else {
            alert.error(r.mensaje);
        }
    }

    const eliminarTrabajo = (posicion) => {
        const trabajosFiltrado = trabajos.filter((i, p) => p !== posicion);
        setTrabajos(trabajosFiltrado);
        setReservaModificada({
            ...reservaModificada,
            'planificado': trabajosFiltrado
        }
        )
    }

    const crearTrabajo = (nuevoTrabajo) => {
        if (nuevoTrabajo && nuevoTrabajo.idCara && nuevoTrabajo.idDiagnostico && nuevoTrabajo.idTratamiento && nuevoTrabajo.idPieza) {
            const nuevoTrabajoPorId = {
                idPieza: nuevoTrabajo.idPieza.id,
                idCara: nuevoTrabajo.idCara.id,
                idDiagnostico: nuevoTrabajo.idDiagnostico.id,
                idTratamiento: nuevoTrabajo.idTratamiento.id
            };
            setReservaModificada({
                ...reservaModificada,
                'planificado': reservaModificada.planificado ? [...reservaModificada.planificado, nuevoTrabajoPorId] : [nuevoTrabajoPorId]
            }
            )
            setTrabajos([
                ...trabajos,
                nuevoTrabajo]
            );
        } else {
            alert.error("Debe ingresar valores en todos los campos");
        }
    }

    const guardarDatosReservaFecha = (value, id) => {
        setReservaModificada({
            ...reservaModificada,
            [id]: value
        });
    };

    const guardarDatosReserva = (e) => {
        setReservaModificada({
            ...reservaModificada,
            [e.target.name]: e.target.value
        });
    };

    const guardarDatosReservaAutocomplete = (event, value) => {
        if (value && value !== "" && value.id && event.target && event.target.id !== "")
            setReservaModificada({
                ...reservaModificada,
                [event.target.id.split("-")[0]]: value.id
            });
    };


    const obtenerReserva = async () => {
        dispatch({ "type": "AVISO_CARGANDO", "data": true });
        const r = await ControllerAgenda.peticionGetCita(id);
        if (!r.resultado) {
            dispatch({ "type": "AVISO_CARGANDO", "data": false });
            alert.error(r.mensaje);
            return;
        }
        setReserva({
            ...r.data,
            fechaInicio: convertirTimezone(r.data.fechaInicio, 'GMT-3'),
            fechaFin: convertirTimezone(r.data.fechaFin, 'GMT-3'),
            idMedico: r.data.medico.idMedico,
            idPaciente: r.data.paciente.idPaciente,
            idConsultorio: r.data.consultorio.idConsultorio,
        });
        setReservaModificada({
            ...r.data,
            fechaInicio: convertirTimezone(r.data.fechaInicio, 'GMT-3'),
            fechaFin: convertirTimezone(r.data.fechaFin, 'GMT-3'),
            idMedico: r.data.medico.idMedico,
            idPaciente: r.data.paciente.idPaciente,
            idConsultorio: r.data.consultorio.idConsultorio,
        });
        dispatch({ "type": "AVISO_CARGANDO", "data": false });
    }

    const obtenerConsultorios = async () => {
        const r = await ControllerConsultorios.peticionGetConsultorios();
        if (r.resultado) {
            setConsultorios(r.data && r.data.registros ? r.data.registros.map((i) => ({ id: i.idConsultorio, label: `${i.nombre}` })) : []);
        } else {
            alert.error(r.mensaje);
        }
    }

    const obtenerProfesionales = async () => {
        const r = await ControllerUsuarios.peticionGetUsuariosPorTipo(2);
        if (r.resultado) {
            setProfesionales(r.data ? r.data.map((i) => ({ id: i.id, label: `${i.nombre1} ${i.nombre2} ${i.apellido1} ${i.apellido2} (Doc: ${i.cedula})` })) : []);
        } else {
            alert.error(r.mensaje);
        }
    }

    const obtenerTratamientos = async () => {
        const r = await ControllerTratamientos.peticionGetTratamientos();
        if (r.resultado) {
            setTratamientos(r.data.registros ? r.data.registros.map((i) => ({ id: i.idTratamiento, label: i.nombre, grupo: i.categoria && i.categoria.nombre })) : []);
        } else {
            alert.error(r.mensaje);
        }
    }

    const obtenerDiagnosticos = async () => {
        const r = await ControllerDiagnosticos.peticionGetDiagnosticos();
        if (r.resultado) {
            setDiagnosticos(r.data ? r.data.map((i) => ({ id: i.idDiagnostico, label: i.nombre })) : []);
        } else {
            alert.error(r.mensaje);
        }
    }

    const obtenerPiezas = async () => {
        if (piezasCargadas && piezasCargadas.length === 0) {
            const r = await ObtenerPiezas();
            if (r) {
                dispatch({ "type": "CARGAR_PIEZAS", "data": r });
                setPiezas(r.map((i) => ({ id: i.idPieza, label: `${i.numero} ${i.nombre}`, grupo: i.grupo })));
            }
        } else {
            setPiezas(piezasCargadas.map((i) => ({ id: i.idPieza, label: `${i.numero} ${i.nombre}`, grupo: i.grupo })));
        }
    }

    const obtenerCaras = async () => {
        if (carasCargadas && carasCargadas.length === 0) {
            const r = await ObtenerCaras();
            if (r) {
                dispatch({ "type": "CARGAR_CARAS", "data": r });
                setCaras(r.map((i) => ({ id: i.idCara, label: i.nombre })));
            }
        } else {
            setCaras(carasCargadas.map((i) => ({ id: i.idCara, label: i.nombre })));
        }
    }

    const obtenerPacientes = async () => {
        const r = await ControllerPacientes.peticionGetPacientes();
        if (r.resultado) {
            setPacientes(r.data && r.data.registros ? r.data.registros.map((i) => ({ id: i.idPaciente, label: `${i.nombre1} ${i.nombre2} ${i.apellido1} ${i.apellido2} (Doc: ${i.documento})` })) : []);
        } else {
            alert.error(r.mensaje);
        }
    }

    useLayoutEffect(() => {
        if (!id) {
            obtenerPiezas();
            obtenerCaras();
            obtenerTratamientos();
            obtenerDiagnosticos();
        }
        obtenerPacientes();
        obtenerProfesionales();
        obtenerConsultorios();
        if (id)
            obtenerReserva();
    }, []);


    useEffect(() => {
        if (id && reserva) {
            setCargaPaciente(reserva && reserva.paciente && pacientes ? pacientes.find((i) => i.id == reserva.paciente.idPaciente) : {})
            setCargaProfesional(reserva && reserva.medico && profesionales ? profesionales.find((i) => i.id == reserva.medico.idMedico) : {})
            setCargaConsultorio(reserva && reserva.consultorio && consultorios ? consultorios.find((i) => i.id == reserva.consultorio.idConsultorio) : {})
        }
    }, [reserva, pacientes, consultorios, profesionales]);

    return (
        <>
            <Dialog
                maxWidth={id ? "md" : "xl"}
                open={visible}
                fullWidth={true}
                TransitionComponent={Transicion}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle disableTypography>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center">
                        <Typography variant="h2">{id ? 'Modificar reserva' : 'Crear reserva'}</Typography>
                        <Box display="flex">
                            <Button color="secondary" variant="contained" onClick={() => cerrar()} style={{ margin: '1rem' }}>
                                Cerrar
                            </Button>
                            <Button color="primary" variant="contained" onClick={() => id ? modificarCita() : crearCita()} style={{ margin: '1rem' }}>
                                {id ? "Guardar" : "Crear"}
                            </Button>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent style={{ padding: '0rem 1rem 0rem 1rem' }}>
                    <div className={classes.plLg4}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} lg={id ? 12 : 6}>
                                <Grid container>
                                    <Grid item xs={12} lg={6}>
                                        <FormGroup>
                                            <FormLabel>Inicio*</FormLabel>
                                            <FormControl>
                                                <AppointmentForm.DateEditor
                                                    key={reserva ? reserva.fechaInicio : 'reserva.fechaInicio'}
                                                    onValueChange={(v) => guardarDatosReservaFecha(v, 'fechaInicio')}
                                                    value={reservaModificada ? reservaModificada.fechaInicio : ""}
                                                />
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <FormGroup>
                                            <FormLabel>Fin*</FormLabel>
                                            <FormControl>
                                                <AppointmentForm.DateEditor
                                                    key={reserva ? reserva.fechaFin : 'reserva.fechaFin'}
                                                    onValueChange={(v) => guardarDatosReservaFecha(v, 'fechaFin')}
                                                    value={reservaModificada ? reservaModificada.fechaFin : ""}
                                                />
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                <FormGroup>
                                    <FormLabel>Paciente*</FormLabel>
                                    <FormControl>
                                        <Autocomplete
                                            id="idPaciente"
                                            options={pacientes}
                                            getOptionLabel={(i) => i.label || ''}
                                            freeSolo
                                            disabled={reserva && reserva.idPaciente ? true : false}
                                            onChange={guardarDatosReservaAutocomplete}
                                            value={cargaPaciente || null}
                                            key={reserva ? reserva.idPaciente : "reserva.idPaciente"}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        />
                                    </FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>Profesional*</FormLabel>
                                    <FormControl>
                                        <Autocomplete
                                            id="idMedico"
                                            options={profesionales}
                                            getOptionLabel={(i) => i.label || ''}
                                            freeSolo
                                            onChange={guardarDatosReservaAutocomplete}
                                            key={reserva ? reserva.idMedico : "reserva.idMedico"}
                                            value={cargaProfesional || null}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        />
                                    </FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>Consultorio*</FormLabel>
                                    <FormControl>
                                        <Autocomplete
                                            id="idConsultorio"
                                            options={consultorios}
                                            getOptionLabel={(i) => i.label || ''}
                                            freeSolo
                                            onChange={guardarDatosReservaAutocomplete}
                                            key={reserva ? reserva.idConsultorio : "reserva.idConsultorio"}
                                            value={cargaConsultorio || null}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        />
                                    </FormControl>
                                </FormGroup>
                                {!id &&
                                    <FormGroup>
                                        <FormLabel>Notas</FormLabel>
                                        <FormControl
                                            variant="filled"
                                            component={Box}
                                            width="100%"
                                            marginBottom="1rem!important"
                                        >
                                            <Box
                                                paddingLeft="0.75rem"
                                                paddingRight="0.75rem"
                                                component={FilledInput}
                                                autoComplete="off"
                                                type="text"
                                                name="detalle"
                                                defaultValue={reserva ? reserva.detalle : ""}
                                                key={reserva ? reserva.detalle : "reserva.detalle"}
                                                multiline rows={3}
                                                onChange={(e) => guardarDatosReserva(e)}
                                                inputProps={{maxLength: 200}}
                                            />
                                        </FormControl>
                                    </FormGroup>}
                            </Grid>
                            {!id &&
                                <Grid item xs={12} lg={6}>
                                    <Grid container>
                                        <Typography variant="h5" style={{"paddingLeft":"0.5rem"}}>Acciones planificadas:</Typography>
                                        <Grid item xs={12} lg={12}>
                                            <AltaTrabajoReserva piezas={piezas} caras={caras} diagnosticos={diagnosticos} tratamientos={tratamientos} funcionConfirmar={crearTrabajo} />
                                        </Grid>
                                        <Grid item xs={12} lg={12} style={{ "marginTop": "1rem" }}>
                                            <Paper style={{"overflowX":"auto"}}>
                                                <Grid
                                                    container
                                                    component={Box}
                                                    padding={'0em 1em 0em 1em'}
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                >
                                                    <Grid item xs={12} lg={12}>
                                                        <ListaTrabajosReserva datos={trabajos} eliminarTrabajo={eliminarTrabajo} />
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Grid>}
                        </Grid>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};