function obtenerFecha(type) {
    let result = "";
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let hour = newDate.getHours();
    let minute = newDate.getMinutes();
    let second = newDate.getSeconds();

    switch (type) {
        case 'date-simple':
            result = `${year}-${month < 10 ? `0${month}` : `${month}`}-${date < 10 ? `0${date}` : `${date}`}`;
            break;
        case 'date':
            result = `${year}-${month < 10 ? `0${month}` : `${month}`}-${date < 10 ? `0${date}` : `${date}`}T${hour < 10 ? `0${hour}` : `${hour}`}:${minute < 10 ? `0${minute}` : `${minute}`}:${second < 10 ? `0${second}` : `${second}`}.000Z`;
            break;
        case 'month':
            result = month;
            break;
        case 'year':
            result = year;
            break;
        default:
            break;
    }
    return result;
}

function obtenerFechaMesesDif(times) {
    var d = new Date();
    d.setMonth(d.getMonth() + times);
    return d.getFullYear() + "/" + (d.getMonth() + 1) + "/01";
}


function convertirTimezone(fecha, tipo) {
    switch (tipo) {
        case "GMT-3":
            return sumarMinutosFecha(fecha, 180);
            break;
        case "UTC":
            return sumarMinutosFecha(fecha, -180);
            break;
        default:
            break;
    }
    return fecha;
}


function obtenerMes(numero) {
    let monthName = "N/A";

    switch (numero) {
        case 1:
            monthName = "Ene"
            break;
        case 2:
            monthName = "Feb"
            break;
        case 3:
            monthName = "Mar"
            break;
        case 4:
            monthName = "Abr"
            break;
        case 5:
            monthName = "May"
            break;
        case 6:
            monthName = "Jun"
            break;
        case 7:
            monthName = "Jul"
            break;
        case 8:
            monthName = "Ago"
            break;
        case 9:
            monthName = "Set"
            break;
        case 10:
            monthName = "Oct"
            break;
        case 11:
            monthName = "Nov"
            break;
        case 12:
            monthName = "Dec"
            break;
        default:
            break;
    }
    return monthName;
}

function formatoFecha(dato, tipo) {
    let newDate = new Date(dato);
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let hour = newDate.getHours();
    let minute = newDate.getMinutes();
    let second = newDate.getSeconds();


    switch (tipo) {
        case "YYYY-MM-DD":
            return `${year}-${month < 10 ? `0${month}` : `${month}`}-${date < 10 ? `0${date}` : `${date}`}`;
            break;
        case "YYYY-MM-DDTHH:MM":
            return `${year}-${month < 10 ? `0${month}` : `${month}`}-${date < 10 ? `0${date}` : `${date}`}T00:01`;
            break;
        case "YYYY/MM/DD":
            return `${year}/${month < 10 ? `0${month}` : `${month}`}/${date < 10 ? `0${date}` : `${date}`}`;
            break;
        case "DD/MM/YYYY a las HH:MM":
            return `${date < 10 ? `0${date}` : `${date}`}/${month < 10 ? `0${month}` : `${month}`}/${year} a las ${hour < 10 ? `0${hour}` : `${hour}`}:${minute < 10 ? `0${minute}` : `${minute}`}`;
            break;
        case "DD/MM/YYYY HH:MM":
            return `${date < 10 ? `0${date}` : `${date}`}/${month < 10 ? `0${month}` : `${month}`}/${year} ${hour < 10 ? `0${hour}` : `${hour}`}:${minute < 10 ? `0${minute}` : `${minute}`}`;
            break;
        case "DD/MM/YYYY":
            return `${date < 10 ? `0${date}` : `${date}`}/${month < 10 ? `0${month}` : `${month}`}/${year}`;
            break;
        default:
            break;
    }

    return `${year}-${month < 10 ? `0${month}` : `${month}`}-${date < 10 ? `0${date}` : `${date}`}T${hour < 10 ? `0${hour}` : `${hour}`}:${minute < 10 ? `0${minute}` : `${minute}`}:${second < 10 ? `0${second}` : `${second}`}.000Z`;
}

function sumarMinutosFecha(fecha, minutos, redondeo = false) {
    const f = new Date(fecha);
    if (redondeo){
        const actualMinutos = f.getMinutes();
        f.setMinutes(actualMinutos <= 30 ? 0 : 30, 0, 0);
    } 
    return new Date(f.getTime() + (minutos * 60 * 1000));
}

function calcularEdad(dob1) {
    var today = new Date();
    var birthDate = new Date(dob1);
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
    }
    return age_now;
}


export { calcularEdad, convertirTimezone, sumarMinutosFecha, obtenerFecha, obtenerFechaMesesDif, obtenerMes, formatoFecha };