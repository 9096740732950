import { formatoFecha } from 'functions/fechas';

const recordatorioCuerpo = (paciente, fecha, medico, consultorio) => {
  return `Hola ${paciente.nombre1} ${paciente.apellido1}!\n
  📆 Te recordamos tu cita odontológica el día ${formatoFecha(fecha,"DD/MM/YYYY a las HH:MM")}.\n
  Aguardamos confirmación, gracias.\n
  ${consultorio.nombre}
  https://www.google.com/maps/search/?api=1&query=${consultorio.direccion.replaceAll(" ","-")}&zoom=20`;
};

const recordatorioAsunto = (consultorio) => {
  return `${consultorio.nombre} - Recordatorio de cita`;
};


export { recordatorioCuerpo, recordatorioAsunto };